
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.clientcabin::v-deep {
    color: $black;
    background-color: $white;
    height: 100%;

    .with-shadow {
        box-shadow: 0 0 25px hsla(0, 0%, 0%, 0.3);
    }
}
